import React, { Component } from 'react'
import Prismic from 'prismic-javascript'
import { Link } from 'gatsby'

class SearchPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      articlesFiltered: [],
      articlesFilteredPage: [],
      inputSearchValue: '',
      currentPage: 0,
      noResultMessage: 'hidden',
    }
    this.search = this.search.bind(this);
    this.displayPagination = this.displayPagination.bind(this);
    this.displayTravels = this.displayTravels.bind(this);
    this.testResults = this.testResults.bind(this);
  }

  componentWillMount() {

    /* Get Data From Prismic */

    const apiEndpoint = 'https://invivox-magazine.cdn.prismic.io/api/v2';
    const accessToken = 'MC5XelROV3ljQUFOQVJlT3A4.77-9fO-_vUED77-977-977-977-977-977-9Midc77-9Pe-_vW9FMO-_ve-_vW_vv73vv73vv73vv71T77-9Ue-_vSw';

    Prismic.api(apiEndpoint, {accessToken}).then(api => {
      api.query(
        [
          Prismic.Predicates.at('document.type', 'article'),
          Prismic.Predicates.not('my.article.category', 'W0M1QiMAAKs41Lcc'),
        ],
        { orderings: '[my.article.date desc]' }
      ).then(response => {
        if (response) {
          response.results.map(result => result.search = `${result.data.title[0].text} - ${result.data.preview[0].text}`);
          this.setState({ articles: response.results, articlesFiltered: response.results }, function(){
            this.displayTravels(this.state.currentPage);
          });
        }
      });
    });
  }

  search(e) {
    const articlesFiltered = this.state.articles.filter((article) => {
      return article.search.toLowerCase().search(
        e.target.value
        .toLowerCase()
        .normalize('NFD').replace(/[\u0300-\u036f]/g, "")) // Supprime la sensibilité aux accents
        !== -1;
    });
    this.setState({ articlesFiltered: articlesFiltered, inputSearchValue: e.target.value }, function(){
      this.displayTravels(0);
      this.testResults();
    });
  }

  displayPagination() {
    const numArticles = this.state.articlesFiltered.length;
    const numPage = Math.ceil(numArticles / 6);
    const pagination = [];

    for(let i=0; i<numPage; i++)
    {
      if (i === this.state.currentPage) {
        pagination.push(<li className="search-pagination-active" key={i} onClick={() => {window.scrollTo(0, 0); this.displayTravels(i)}}>{i+1}</li>)
      }
      else {
        pagination.push(<li key={i} onClick={() => {window.scrollTo(0, 0); this.displayTravels(i)}}>{i+1}</li>)
      }
    }
    return pagination;
  }

  displayTravels(index) {
    const travelPerPage = 6;
    const startIndex = index * travelPerPage;
    const endIndex = (index+1) * travelPerPage;
    const articlesFilteredPage = this.state.articlesFiltered.slice(startIndex, endIndex);
    this.setState({articlesFilteredPage, currentPage: index});
  }

  testResults() {
    if (this.state.articlesFiltered.length === 0) {
      this.setState({noResultMessage: 'show'});
    } else {
      this.setState({noResultMessage: 'hidden'});
    }
  }

  render () {
    //const location = this.props.location;
    return (
      <div>
        <section className="nopadding bg-purple">
          <div className="searchbox-container wrapper--s">
            <span className="inv-icon icon-search fontsize-xxl c-white marginright-m" />
            <div className="searchbox">
              <input
                className=""
                type="text"
                name="search"
                placeholder="Search..."
                value={this.state.inputSearchValue}
                onChange={this.search} />
            </div>
          </div>
        </section>
        <section>
          <div className="wrapper--s">
            <div className={`no-results ${this.state.noResultMessage}`} >
              <p>We couldn't find any article matching your search.</p>
            </div>
            <div className="grid breakpoint-m-grid--3 grid-1 grid--m">
              {
                this.state.articlesFilteredPage.map(article => (
                  <div key={ article.id } className="magazine-article">
                    <Link to={`/post/${article.uid}/`}>
                      <div
                        className="article-thumbnail width-100 display-block position-relative"
                        style={{
                          backgroundImage: `url('${article.data.cover.url}')`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center'
                        }}>
                      </div>
                    </Link>
                    <Link to={`/category/${ article.data.category.uid }/`}>
                      <span className={`article-category article-category--${ article.data.category.uid }`}>{article.data.category.slug}</span>
                    </Link>
                    <Link to={`/post/${article.uid}/`}>
                      <span className="article-title">{article.data.title[0].text}</span>
                      { article.data.preview[0].text &&
                        <span className="article-preview">{article.data.preview[0].text}</span>
                      }
                    </Link>
                  </div>
                ))
              }
            </div>
              <ul className="search-pagination">
                {this.displayPagination().map(page => page)}
              </ul>
          </div>
        </section>
      </div>
    )
  }
}

export default SearchPage